import { useQuery } from '@tanstack/vue-query'
import type { ICountries } from './types'

export type ICountriesMultSelect = {
  value: string
  label: string
}

// GET /countries
export const useApiCountries = () => {
  const apiURL = useRuntimeConfig().public.apiURL
  const url = `${apiURL}/countries`

  const query = useQuery<ICountries[]>({
    queryKey: ['countries'],
    queryFn: () =>
      $fetch<ICountries[]>(url, {
        credentials: 'include',
      }),
  })

  const countries = computed(() => query.data.value ?? [])

  const countriesToMultiSelect = computed<ICountriesMultSelect[]>(() => {
    return (query.data.value ?? []).map((country) => {
      return {
        value: country.id || '',
        label: country.name || '',
      }
    })
  })

  return {
    ...query,
    countries,
    countriesToMultiSelect,
  }
}
